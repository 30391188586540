<template>
  <v-container>
    <v-row class="center-content">
      <v-col cols="6" class="offset-3">
        <v-card class="mb-7  py-5">
          <v-card-title class="d-flex justify-center">
            <span class="text-h5">Profile</span>
          </v-card-title>
          <v-card-text class="d-flex justify-center" style="border">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="10" class="offset-1">
                  <v-text-field
                    label="Full Name *"
                    :rules="[(v) => !!v || 'Full Name is required']"
                    v-model="profileForm.fullName"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="offset-1">
                  <v-text-field
                    label="Email *"
                    v-model="profileForm.email"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="offset-1">
                  <v-btn color="primary" class="w-100" @click="changeProfile()"> Change </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
import Snackbar from './Snackbar.vue';
export default {
  name: "Profile",
  components: {
    Snackbar
  },
  data: () => ({
    userId:'',
    profileForm:{
      fullName:'',
      email:''
    },
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
    ],
    valid:true,
    userDetail:{}
  }),
  computed: {},
  mounted: function () {
    this.getLoginUserDetail();
  },
  created: function () { },
  methods: {
    getLoginUserDetail(){
      axios.get(this.Node_JS_HOST + "/api/v1/web/auth/")
        .then((res) => {
          this.userDetail = res.data.data;
          this.userId = this.userDetail._id;
          this.profileForm.fullName = this.userDetail.fullName;
          this.profileForm.email = this.userDetail.email;
        })
    },
    changeProfile(){
      if(this.$refs.form.validate()){
        axios.put(this.Node_JS_HOST + "/api/v1/web/user/"+this.userId, this.profileForm)
        .then((res) => {
          if(res){
            this.snackbarText = 'Profile Updated Successfully';
            this.isShowSnackbar = true;
          }
        })
        .catch((error) => {
          this.errorHandle(error);
          this.snackbarText = 'Email already in used.';
          this.isShowSnackbar = true;
        });
      }
    }

  },
};
</script>
<style scoped>
.w-100 {
  width: 100%;
}
::v-deep.container {
  height: 100%;
}
.center-content{
    display: flex;
    height: 100%;
    align-items: center;
}
</style>